import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { IoLogoInstagram } from 'react-icons/io';
import SocialProfile from '../../../components/SocialProfile/SocialProfile';
import { AboutWrapper, AboutImage, AboutPageTitle, AboutDetails, SocialProfiles } from './style';

const SocialLinks = [
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/briedownes/',
    tooltip: 'Instagram',
  },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/brielle_sitting.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h1>About Brielle</h1>
      </AboutPageTitle>

      <AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </AboutImage>

      <AboutDetails>
        <h2>About Me</h2>
        <p>
          Hey there! My name is Brielle, and I am 27 years old. I am a Recreation Therapist
          professionally, and live in the great state of New Jersey, where I have lived almost my
          entire life. I am the oldest of 6 children and come from a very large and in charge
          Irish/Italian family. Currently, I am working as a Recreation Director at a
          skilled-nursing facility in Monmouth County, where I spend my days working with long-term
          care residents on leisure-based therapies to promote their wellness. I am also currently
          pursuing a master’s degree in Health Services Administration. Outside of my professional
          life, I am a lover of music, cooking, singing, yoga and spending time with my friends. I
          have a 1½ year old dog named Winnie who is the sunshine of my life. I have been living
          with Type 1 Diabetes for 18 years.
        </p>
        <p>
          I started this blog as an outlet, not just for myself, but for all of the other people out
          there that struggle with Type 1 Diabetes. There are so many of us, but I know from
          personal experience how closed off I can feel from others who are like me. My goal is to
          be able to share my story with other individuals living with TID (or who just want to
          hang), to start conversations, and help us not to feel so alone. I hope you enjoy reading
          these posts every week as much as I enjoy writing them.
        </p>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
