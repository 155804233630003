import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../containers/About';

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <Layout>
      <SEO title="About Me" description="A blog by Brielle Downes" />
      <About />
    </Layout>
  );
};

export default AboutPage;
